<template>
  <div class="h-full relative">
    <ul
      ref="floornav"
      class="
        floor-nav
        flex
        font-semibold
        space-x-3
        md:space-x-6
        border-b border-black
        py-2
        px-3
        md:px-6
        z-50
        relative
        sticky
        md:top-10
        leading-tight
      "
      :style="themeStylesWithBackground"
    >
      <li :class="{ active: activeFloor == 'all' }">
        <router-link :to="{ params: { floor: 'all' } }">All Floors</router-link>
      </li>
      <li :class="{ active: activeFloor == -1 }">
        <router-link :to="{ params: { floor: -1 } }">{{
          $route.params.lang === 'cn' ? '地下一层' : 'Floor -1'
        }}</router-link>
      </li>
      <li :class="{ active: activeFloor == 0 }">
        <router-link :to="{ params: { floor: 0 } }">{{
          $route.params.lang === 'cn' ? '底楼' : 'Floor 0'
        }}</router-link>
      </li>
      <li :class="{ active: activeFloor == 1 }">
        <router-link :to="{ params: { floor: 1 } }">{{
          $route.params.lang === 'cn' ? '一楼' : 'Floor +1'
        }}</router-link>
      </li>
    </ul>

    <span id="bounce-top" />

    <div class="md:flex relative z-20">
      <div class="relative md:w-1/3 border-r max-w-sm z-10 h-0p">
        <div class="p-3 md:p-6 border-b" :style="themeStylesWithBackground">
          <ul>
            <li v-for="artist in floorArtists" :key="artist.id" class="mb-2">
              <router-link
                :to="`/${lang}/publication/${$route.params.slug}/artworks/${
                  $route.params.floor == 'all' ? 'all' : $route.params.floor
                }/${artist.id}/${artist.slug}`"
                class="flex items-center hover-toggle text-lg group"
              >
                <span
                  class="
                    dot
                    hidden
                    w-4
                    h-4
                    rounded-full
                    mr-3
                    group-hover:inline-block
                  "
                  :style="dotStyle"
                ></span>
                <span v-html="artist.display_title"></span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="relative w-5/6 md:w-2/3 z-10 max-w-4xl" ref="content">
        <div>
          <div
            v-if="currentArtworks"
            class="content text-text"
            :key="activeArtist.id"
          >
            <div>
              <div
                v-for="(artwork, count) in currentArtworks"
                :key="artwork.id"
                id="artwork-column"
                class="
                  pt-1
                  border-b
                  max-w-4xl
                  border-l border-r
                  artwork-column
                  last:border-b-0
                "
                :style="themeStylesWithBackground"
              >
                <div class="flex items-center mb-12 m-6" v-if="count == 0">
                  <h2
                    v-html="activeArtist.display_title"
                    class="text-xl pr-3 font-semibold"
                  ></h2>
                  <div
                    class="
                      flex
                      items-center
                      border border-black
                      rounded-full
                      py-2
                      px-3
                      toggler
                      cursor-pointer
                      -mt-1
                    "
                    @click="showBio = !showBio"
                    :style="bioBorderStyle"
                  >
                    <div class="h-1 w-1 rounded-full mx-1" :style="dotStyle" />
                    <div class="h-1 w-1 rounded-full mx-1" :style="dotStyle" />
                    <div class="h-1 w-1 rounded-full mx-1" :style="dotStyle" />
                  </div>
                </div>
                <div
                  v-if="showBio && count == 0"
                  v-html="activeArtist.content"
                  class="mx-6 mb-6 -mt-8 p-spacing font-normal text-xl"
                  :class="{ 'border-b': showBio }"
                ></div>
                <div>
                  <h3
                    v-html="artwork.display_title"
                    class="text-xl mx-6 font-semibold"
                    :class="{ 'mt-3': count > 0 }"
                  ></h3>
                  <div
                    v-html="artwork.details"
                    class="mt-1 details text-lg mx-6"
                  ></div>
                </div>
                <div
                  class="mt-8 text-lg mobile-content p-indent"
                  v-html="artwork.installation_media"
                />
                <div
                  class="text-lg m-3 md:m-6 p-spacing"
                  v-html="artwork.description"
                />
                <div
                  class="text-lg mobile-content"
                  v-html="artwork.additional_media"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden md:block absolute inset-0 cursor-n-resize"
        @click="scrollToTop"
      ></div>
      <div
        class="md:hidden fixed bottom-0 right-0 p-4 pb-8"
        @click="scrollToTop"
      >
        Top
      </div>
    </div>

    <Bounce
      v-for="image in content.bounce_images"
      :key="image.filename"
      :image="image"
    />
  </div>
</template>

<script>
import Bounce from '@/components/Bounce'
import { uniqWith, isEqual } from 'lodash'
export default {
  name: 'Artworks',
  props: ['content', 'themeStyles', 'themeStylesWithBackground'],
  data() {
    return {
      showBio: false,
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    getArtists(items) {
      return uniqWith(
        items
          .map((x) => {
            return x.related.filter((y) => y.blueprint === 'Entity')
          })
          .flat()
          .sort((a, b) => {
            var nameA = a.display_title.toUpperCase() // ignore upper and lowercase
            var nameB = b.display_title.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
          }),
        isEqual
      )
    },
    scrollToTop() {
      document.body.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
  computed: {
    bioBorderStyle() {
      if (this.$props.themeStyles) {
        return {
          'border-color': this.$props.themeStyles.color,
        }
      } else {
        return {}
      }
    },
    dotStyle() {
      if (this.$props.themeStyles) {
        return {
          background: this.$props.themeStyles.color,
        }
      } else {
        return {
          background: 'black',
        }
      }
    },
    activeFloor() {
      return this.$route.params.floor
    },
    lang() {
      return this.$route.params.lang || 'en'
    },
    currentArtworks() {
      if (this.$props.content && this.$route.params.artist) {
        return this.artworks.filter(
          (x) =>
            x.related.filter((y) => y.id == this.$route.params.artist).length >
            0
        )
      } else {
        return null
      }
    },
    artworks() {
      return this.$props.content.f1.concat(
        this.$props.content.f0,
        this.$props.content.f_1
      )
    },
    floorArtworks() {
      if (this.activeFloor == 'all') {
        return this.$props.content.f1.concat(
          this.$props.content.f0,
          this.$props.content.f_1
        )
      } else if (this.activeFloor == '1') {
        return this.$props.content.f1
      } else if (this.activeFloor == '0') {
        return this.$props.content.f0
      } else {
        return this.$props.content.f_1
      }
    },
    artists() {
      if (this.$props.content) {
        return this.getArtists(this.artworks)
      } else {
        return false
      }
    },
    floorArtists() {
      if (this.$props.content) {
        return this.getArtists(this.floorArtworks)
      } else {
        return false
      }
    },
    activeArtist() {
      if (this.$route.params.artist) {
        return this.artists.filter((x) => x.id === this.$route.params.artist)[0]
      } else if (this.active) {
        return this.artists.filter((x) => x.id === this.active)
      } else {
        return null
      }
    },
  },
  components: {
    Bounce,
  },
  mounted() {
    if (window.innerWidth > 768 && this.content.banner.bg) {
      document.body.style.backgroundImage = `url('${this.content.banner.bg}')`
      document.body.style.backgroundSize = 'contain'
      document.body.style.backgroundRepeat = 'no-repeat'
      document.body.style.backgroundPosition = 'center'
      document.body.style.backgroundAttachment = 'fixed'
    }
    if (window.innerWidth <= 768 && this.content.banner_mobile.bg) {
      document.body.style.backgroundImage = `url('${this.content.banner_mobile.bg}')`
      document.body.style.backgroundSize = 'contain'
      document.body.style.backgroundRepeat = 'no-repeat'
      document.body.style.backgroundPosition = 'center bottom'
    }
  },
  beforeDestroy() {
    document.body.style.backgroundImage = ''
  },
  watch: {
    $route() {
      if (window.innerWidth < 768) {
        if (this.$route.params.artist) {
          this.$scrollTo('#artwork-column', {
            offset: -this.$refs.floornav.getBoundingClientRect().bottom + 30
          })
        } else {
          this.$scrollTo('#project-nav')
        }
      } else {
        this.$scrollTo('#app')
      }
    },
  },
}
</script>

<style lang="css" scoped>
.h-0p {
  height: 0%;
}

.artwork-column {
  margin-left: -1px;
}

.floor-nav li {
  @apply cursor-pointer;
  &.active,
  &:hover {
    @apply italic;
  }
}

.router-link-active {
  .dot {
    @apply inline-block;
  }
}

.backer >>> img {
  @apply w-full flex justify-end;
}
>>> figcaption {
  @apply text-sm leading-tight m-3;
}

>>> audio {
  @apply my-6 px-6 w-full;
}

.p-indent >>> p {
  @apply px-6;
}
>>> .zoom-block {
  width: 100vw !important;
  max-width: 100vw !important;
}
@media screen and (max-width: 768px) {
  ul.floor-nav {
    top: 89px;
  }
}
</style>
